<template>
  <v-card>
    <v-card-title class="align-start">
      Edit Label
    </v-card-title>

    <v-card-text class="text-no-wrap text--primary">
      <v-form v-model="valid">
        <v-container>
          <v-row>
            <v-col cols="12">
              <v-text-field
                v-model="pb_no"
                :rules="rules.nameRules"
                :counter="10"
                label="PB #"
                required
              ></v-text-field>
            </v-col>

            <v-col cols="12">
              <v-text-field
                v-model="name"
                :rules="rules.nameRules"
                :counter="10"
                label="Name"
                required
              ></v-text-field>
            </v-col>

            <v-col cols="12">
              <v-text-field
                v-model="email"
                :rules="rules.emailRules"
                label="E-mail"
                required
              ></v-text-field>
            </v-col>

            <v-col cols="12">
              <v-text-field
                v-model="company"
                :counter="10"
                label="Company"
              ></v-text-field>
            </v-col>

            <v-col cols="12">
              <v-text-field
                v-model="rep_id"
                :counter="10"
                label="Rep ID"
              ></v-text-field>
            </v-col>

            <v-col cols="12">
              <v-text-field
                v-model="password"
                :append-icon="show_password ? 'mdi-eye' : 'mdi-eye-off'"
                :rules="[rules.required, rules.min]"
                :type="show_password ? 'text' : 'password'"
                label="Password"
                hint="At least 8 characters"
                counter
                @click:append="show_password = !show_password"
              ></v-text-field>
            </v-col>
          </v-row>
        </v-container>
      </v-form>
    </v-card-text>
  </v-card>
</template>

<script>
  export default {
    data: () => ({
      valid: false,
      pb_no: '',
      name: '',
      company: '',
      rep_id: '',
      show_password: false,
      password: '',
      email: '',
      rules: {
        required: value => !!value || 'Required.',
        min: v => v.length >= 8 || 'Min 8 characters',
        nameRules: [
          v => !!v || 'Name is required',
          v => v.length <= 10 || 'Name must be less than 10 characters',
        ],
        emailRules: [
          v => !!v || 'E-mail is required',
          v => /.+@.+/.test(v) || 'E-mail must be valid',
        ],
      },
    }),
  }
</script>
